.image-cropper {
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 50%;
}

.lottie-home {
    width: 550px;
}

.lottie-servicios {
    height: 160px;
}

.lottie-programas {
    width: 550px;
}

.lottie-reporte {
    width: 400px;
}

@media (max-width: 820px) {
    .lottie-home, .lottie-servicios, .lottie-programas, .lottie-reporte {
      width: auto;
    }
  }

svg {
    transform: none!important;
}

.header {
    line-height: 30px!important;
}

.whatsapp {
    position:fixed;
    width:60px;
    height:60px;
    bottom:40px;
    right:40px;
    background-color:#25d366;
    color:#FFF;
    border-radius:50px;
    text-align:center;
    font-size:30px;
    z-index:100;
  }

.carousel-container {
    width: 100%;
}

