@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --card-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
  font-family: 'Poppins';
}

* {
  scroll-behavior: smooth;
}